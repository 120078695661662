module controllers {
    'use strict';
    export module compliance {
        interface IconsignmentComplianceScope extends ng.IScope { IsLoading: boolean; }

        interface IconsignmentComplianceParams extends ng.ui.IStateParamsService {
            transcationId: number;
        }

        export class ConsignmentComplianceCtrl {
            static $inject = [
                "$scope",
                '$rootScope',
                'generalService',
                '$q',
                '$stateParams',
                "$anchorScroll",
                "$state",
                "bsLoadingOverlayService",
                "lookupService",
                "$timeout",
                '$transitions',
                'consignmentTemplateService',
                'documentRepositoryService',
                '$uibModal',
                'userAccountService',
                "isInbound"];

            userDesignation: ng.resource.IResourceArray<interfaces.applicationcore.IUserEntityDesignation>;
            currentUserId: string;
            transactionId: number;
            searchAccordian: boolean = true;
            IsLoading: boolean = false;
            summaryOpen: boolean = false;
            showAll: boolean = false;

            consignmentCompliance: interfaces.compliance.IConsignmentCompliance;
            SelectedStatusId: number;
            breadCrumbDesc: string;
            apiDocumentList: uiGrid.IGridApi;

            customAttributeAnswerList: Array<interfaces.compliance.ICustomAttributeAnswer>;

            constructor(
                private $scope: IconsignmentComplianceScope,
                private $rootScope: interfaces.applicationcore.IRootScope,
                public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService,
                private $stateParams: IconsignmentComplianceParams,
                private $anchorScroll: ng.IAnchorScrollService,
                private $state: ng.ui.IStateService,
                public bsLoadingOverlayService,
                private lookupService: interfaces.applicationcore.ILookupService,
                public $timeout: ng.ITimeoutService,
                public $transitions: ng.ui.core.ITransition,
                private consignmentTemplateService: interfaces.compliance.IConsignmentTemplateService,
                private documentRepositoryService: interfaces.documentManagement.IDocumentRepositoryService,
                private $uibModal: ng.ui.bootstrap.IModalService,
                public userService: interfaces.applicationcore.IUserAccountService,
                public isInbound: boolean) {

                this.transactionId = $stateParams.transactionId;
                this.currentUserId = $rootScope.userId;
                this.loadControls();

            }

            loadControls() {

                var loadPromises: ng.IPromise<any>[] = [];
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'compliance.view'
                },
                    () => {
                        loadPromises.push(this.GetConsignmentCompliance());
                        return this.$q.all(loadPromises);
                    });
            }

            GetConsignmentCompliance() {
                this.IsLoading = true;
                return this.consignmentTemplateService.getConsignmentCompliance(this.transactionId).get((data) => {

                    this.consignmentCompliance = data;

                    this.gvwComplianceDocumentList.data = this.consignmentCompliance.ComplianceDocumentList;

                    this.breadCrumbDesc = this.consignmentCompliance.ConsignmentNo;

                }, (data) => {
                    this.IsLoading = false;
                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }


            getDocuments(showAll:boolean) {
                return this.documentRepositoryService.getDocumentRepository().query({ referenceNumber: this.consignmentCompliance.ConsignmentNo, 
                    systemType: this.isInbound? Enum.EnumSystemType.ImportConsignment:Enum.EnumSystemType.Consignment,
                    ownerEntityId: this.consignmentCompliance.OwnerEntityId, showAll:showAll }, (documentRepository: interfaces.documentManagement.IDocumentRepository) => {
                    this.consignmentCompliance.DocumentRepository = documentRepository;

                }, (errorResponse) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>errorResponse.data);
                }).$promise;
            }

            refreshDocuments(showAll:boolean) {
                this.showAll = showAll;
                this.consignmentCompliance.DocumentRepository.DocumentRepositoryFiles = null;
                return this.getDocuments(showAll);
            }

            printDocuments() {
                this.$state.go("auth.Compliance.Update.Documents", { version: 1 });
            }

            SaveClick() {

                this.customAttributeAnswerList = [];

                angular.forEach(this.consignmentCompliance.TemplateGroupList, (Value, TemplateGroupId) => {

                    angular.forEach(Value.CompliancQuestionList, (QuestionValue, CustomAttributeId) => {

                        if (QuestionValue.Answer != QuestionValue.OriginalAnswer && QuestionValue.ControlTypeId === 58) {
                            this.customAttributeAnswerList.push({ CustomAttributeId: QuestionValue.CustomAttributeId, ControlTypeId: QuestionValue.ControlTypeId, AnswerText: QuestionValue.Answer, AnswerDatetime: null, AnswerDropDown: null, AnswerNumeric:null });
                        }
                        
                        if (QuestionValue.AnswerDate != QuestionValue.OriginalAnswerDate && QuestionValue.ControlTypeId === 557) {
                            this.customAttributeAnswerList.push({ CustomAttributeId: QuestionValue.CustomAttributeId, ControlTypeId: QuestionValue.ControlTypeId, AnswerDatetime: QuestionValue.AnswerDate, AnswerText: null, AnswerDropDown: null, AnswerNumeric: null});
                        }

                        if (QuestionValue.AnswerDropDown != QuestionValue.OriginalAnswerDropDown && QuestionValue.ControlTypeId === 57) {
                            this.customAttributeAnswerList.push({ CustomAttributeId: QuestionValue.CustomAttributeId, ControlTypeId: QuestionValue.ControlTypeId, AnswerDropDown: +QuestionValue.AnswerDropDown, AnswerText: null, AnswerDatetime: null, AnswerNumeric: null });
                        }

                        if (QuestionValue.AnswerNumeric != QuestionValue.OriginalAnswerNumeric && QuestionValue.ControlTypeId === 556) {
                            this.customAttributeAnswerList.push({ CustomAttributeId: QuestionValue.CustomAttributeId, ControlTypeId: QuestionValue.ControlTypeId, AnswerNumeric: QuestionValue.AnswerNumeric, AnswerText: null, AnswerDatetime: null, AnswerDropDown: null });
                        }

                    });

                });

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'compliance.view'
                }, () => {
                    return this.Save(this.transactionId, this.customAttributeAnswerList);;
                });        

            }

            Save(transactionId: number, complianceQuestionAnswerList: Array<interfaces.compliance.ICustomAttributeAnswer>) {

                return this.consignmentTemplateService.saveCompliance(transactionId).save(
                     complianceQuestionAnswerList ,
                    (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);

                        if (!data.HasErrorMessage) {

                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'compliance.view'
                                }, () => {
                                    return this.GetConsignmentCompliance();
                                });
                            });


                        }
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            SaveClose() {

                this.customAttributeAnswerList = [];

                angular.forEach(this.consignmentCompliance.TemplateGroupList, (Value, TemplateGroupId) => {

                    angular.forEach(Value.CompliancQuestionList, (QuestionValue, CustomAttributeId) => {

                        if (QuestionValue.Answer != QuestionValue.OriginalAnswer && QuestionValue.ControlTypeId === 58) {
                            this.customAttributeAnswerList.push({ CustomAttributeId: QuestionValue.CustomAttributeId, ControlTypeId: QuestionValue.ControlTypeId, AnswerText: QuestionValue.Answer, AnswerDatetime: null, AnswerDropDown: null, AnswerNumeric: null });
                        }

                        if (QuestionValue.AnswerDate != QuestionValue.OriginalAnswerDate && QuestionValue.ControlTypeId === 557) {
                            this.customAttributeAnswerList.push({ CustomAttributeId: QuestionValue.CustomAttributeId, ControlTypeId: QuestionValue.ControlTypeId, AnswerDatetime: QuestionValue.AnswerDate, AnswerText: null, AnswerDropDown: null, AnswerNumeric: null });
                        }

                        if (QuestionValue.AnswerDropDown != QuestionValue.OriginalAnswerDropDown && QuestionValue.ControlTypeId === 57) {
                            this.customAttributeAnswerList.push({ CustomAttributeId: QuestionValue.CustomAttributeId, ControlTypeId: QuestionValue.ControlTypeId, AnswerDropDown: +QuestionValue.AnswerDropDown, AnswerText: null, AnswerDatetime: null, AnswerNumeric: null });
                        }

                        if (QuestionValue.AnswerNumeric != QuestionValue.OriginalAnswerNumeric && QuestionValue.ControlTypeId === 556) {
                            this.customAttributeAnswerList.push({ CustomAttributeId: QuestionValue.CustomAttributeId, ControlTypeId: QuestionValue.ControlTypeId, AnswerNumeric: QuestionValue.AnswerNumeric, AnswerText: null, AnswerDatetime: null, AnswerDropDown: null });
                        }

                    });

                });

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'compliance.view'
                }, () => {
                    return this.Save(this.transactionId, this.customAttributeAnswerList).then(() => { this.Close(); })
                });      
            }

            Close() {            
                this.$state.go("^");
            }

            OpenHistory() {

                return this.consignmentTemplateService.getComplianceStatusHistoryList(this.transactionId).query((statusHistoryList: Array<interfaces.compliance.ConsignmentComplianceStatusHistory>) => {
                    
                    this.DisplayStatusHistory(statusHistoryList);
                }
                    , (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }


            DisplayStatusHistory(StatusHistoryList: Array<interfaces.compliance.ConsignmentComplianceStatusHistory>) {
                //Get delivery status
                return this.$uibModal.open({
                    animation: true,
                    template: `
                        <div class="modal-content" >
                            <form name="statusHistory">
                                <div class="gts-padding">
                                    <fieldset id="statusHistory">
                                    <legend>
                                       Compliance Status History
                                    </legend>                                            
                                            <div class="container-fluid row">
                                                            <table class="table table-hover">
                                                                        <thead>
                                                                            <tr>
                                                                                <th>Previous Status</th>
                                                                                <th>New Status</th>
                                                                                <th>Comments</th>
                                                                                <th>User</th>
                                                                                <th>Date and Time</th>
                                                                            </tr>
                                                                        </thead>
                                                                        <tbody>
                                                                            <tr ng-repeat="statusHistory in statusHistoryCtrl.StatusHistoryList">                    
                                                                                <td>{{statusHistory.PreviousStatus}}</td>
                                                                                <td>{{statusHistory.CurrentStatus}}</td>
                                                                                <td>{{statusHistory.Comments}}</td>
                                                                                <td>{{statusHistory.User}}</td>
                                                                                <td>{{statusHistory.StatusUpdateStampDate | momentDateFilter: 'YYYY/MM/DD HH:mm:ss'}}</td>
                                                                            </tr>
                                                                        </tbody>
                                                            </table>                     
                                             </div>  
                                            <div class="gts-padding pull-right">                                               
                                                    <button type="button" class="btn btn-primary"  ng-click="statusHistoryCtrl.ok()">Ok</button>                                                   
                                            </div>                                       
                                    </fieldset>
                                </div>
                            </form>
                        </div>
                    `
                    ,
                    controller: class statusHistoryCtrl {
                        StatusHistoryList: Array<interfaces.compliance.ConsignmentComplianceStatusHistory>;
                        constructor($scope, private $uibModalInstance: angular.ui.bootstrap.IModalInstanceService) {
                            this.StatusHistoryList = StatusHistoryList;
                        }

                        ok() {

                            this.$uibModalInstance.close();
                        }

                    },
                    controllerAs: "statusHistoryCtrl",
                    size: "lg",
                    resolve: {
                    }
                }).result;
            }

            AuditSignOffClick() {
                this.$rootScope.Messages = [];
                var HasErrors = false;

                if (!this.consignmentCompliance.AuditComments) {

                    HasErrors = true
                    this.$rootScope.Messages.push({
                        LocaleDescription: "A comment is required.",
                        TypeEnum: Enum.EnumMessageType.Warning
                    })
                }

                if (!HasErrors) {
                    this.generalService.displayConfirmationBox("Confirm?", "Approve Compliance transaction?").then((result: boolean) => {
                        if (result) {

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'compliance.view'
                            }, () => {
                                return this.AuditSignOff(this.transactionId, this.consignmentCompliance.AuditComments);
                            });
                        }
                    });
                }

            }

            AuditSignOff(transactionId: number, comments: string) {
                return this.consignmentTemplateService.auditSignOff().save(
                    { transactionId, comments },
                    (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);

                        if (!data.HasErrorMessage) {

                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'compliance.view'
                                }, () => {
                                    return this.GetConsignmentCompliance();
                                });
                            });


                        }
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }


            AuditRejectClick() {
                this.$rootScope.Messages = [];
                var HasErrors = false;

                if (!this.consignmentCompliance.AuditComments) {

                    HasErrors = true
                    this.$rootScope.Messages.push({
                        LocaleDescription: "A comment is required.",
                        TypeEnum: Enum.EnumMessageType.Warning
                    })
                }

                if (!HasErrors) {
                    this.generalService.displayConfirmationBox("Confirm?", "Reject Compliance transaction?").then((result: boolean) => {
                        if (result) {

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'compliance.view'
                            }, () => {
                                return this.AuditReject(this.transactionId, this.consignmentCompliance.AuditComments);
                            });
                        }
                    });
                }

            }

            AuditReject(transactionId: number, comments: string) {
                return this.consignmentTemplateService.auditReject().save(
                    { transactionId, comments },
                    (data: interfaces.applicationcore.IMessageHandler) => {
                        this.generalService.displayMessageHandler(data);

                        if (!data.HasErrorMessage) {

                            this.$timeout(() => {
                                this.bsLoadingOverlayService.wrap({
                                    referenceId: 'compliance.view'
                                }, () => {
                                    return this.GetConsignmentCompliance();
                                });
                            });


                        }
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            ChangeStatusClick() {

                this.$rootScope.Messages = [];
                var HasErrors = false;

                if (!this.SelectedStatusId) {

                    HasErrors = true;
                this.$rootScope.Messages.push({
                    LocaleDescription: "Please select a Status.",
                    TypeEnum: Enum.EnumMessageType.Warning
                    })
                }

                if (!this.consignmentCompliance.NewStatusComments) {

                    HasErrors = true
                    this.$rootScope.Messages.push({
                        LocaleDescription: "A comment is required.",
                        TypeEnum: Enum.EnumMessageType.Warning
                    })
                }

                if (!HasErrors) {
                    this.generalService.displayConfirmationBox("Confirm?", "Change Status?").then((result: boolean) => {
                        if (result) {

                            this.bsLoadingOverlayService.wrap({
                                referenceId: 'compliance.view'
                            }, () => {
                                return this.changeStatus(this.transactionId, this.SelectedStatusId, this.consignmentCompliance.NewStatusComments);;
                            });                            
                        }
                    });
                }

            }

            changeStatus(transactionId: number, nextStatusId: number, comments: string) {

                       return  this.consignmentTemplateService.changeComplianceStatus().save(
                            { transactionId, nextStatusId, comments },
                           (data: interfaces.applicationcore.IMessageHandler) => {
                               this.generalService.displayMessageHandler(data);

                               if (!data.HasErrorMessage) {

                                   this.$timeout(() => {
                                       this.bsLoadingOverlayService.wrap({
                                           referenceId: 'compliance.view'
                                       }, () => {
                                           return this.GetConsignmentCompliance();
                                       });
                                   });

                                  
                               }
                            }, (failureData) => {
                                this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                           }).$promise;
                               
            }

            LoadNextStatusList() {
                if (this.consignmentCompliance && this.consignmentCompliance.NextStatusList) {
                    return this.consignmentCompliance.NextStatusList;
                }
            }

            public registerDocumentApi(gridApi: uiGrid.IGridApi) {
                this.apiDocumentList = gridApi;
            }

            public gvwComplianceDocumentList: uiGrid.IGridOptions = {
                data: [],
                enableFiltering: true,
                useExternalFiltering: false,
                enableCellEdit: false,
                useExternalSorting: false,
                multiSelect: false,
                enableColumnResizing: true,
                enableRowSelection: false,
                enableFullRowSelection: false,
                useExternalPagination: false,
                enableRowHeaderSelection: false,
                enableHorizontalScrollbar: false,
                enablePinning: false,
                showGridFooter: false,
                onRegisterApi: (gridApi) => { this.registerDocumentApi(gridApi) },
                columnDefs: [
                    {
                        name: "DocumentCode",
                        displayName: "Document Type",
                        field: 'DocumentCode',
                        cellClass: 'text-left',
                        width: 120,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    },
                    {
                        name: "DocumentDescription",
                        displayName: "Document Description",
                        field: 'DocumentDescription',
                        cellClass: 'text-left',
                        width: 320,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    },
                    {
                        name: "DateTypeDescription",
                        displayName: "Date Type",
                        field: 'DateTypeDescription',
                        cellClass: 'text-left',
                        width: 160,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    },
                     {
                         name: "NumberOfDays",
                         displayName: "Number of Days",
                         field: 'NumberOfDays',
                        cellClass: 'text-left',
                        width: 120,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }
                    ,
                    {
                        name: "DateToBeResolved",
                        displayName: "Date to be Resolved",
                        field: 'DateToBeResolved',
                        cellClass: 'text-left',
                        width: 140,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD | momentDateFilter: 'YYYY/MM/DD'}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }
                    ,
                    {
                        name: "IsResolved",
                        displayName: "Resolved",
                        field: 'IsResolved',
                        cellClass: 'text-left',
                        width: 120,
                        cellTemplate: `<div class="ui-grid-cell-contents">{{MODEL_COL_FIELD}}</div>`,
                        filterHeaderTemplate: `
                                <div class="ui-grid-filter-container" ng-repeat="colFilter in col.filters">
                                    <div class="input-group-sm">
                                        <input type="number" class="form-control" ng-model="colFilter.term" ng-model-options="{ debounce: 1000 }"/>
                                    </div>
                                </div>`,
                        filter: {
                            condition: (searchTerm, cellValue) => {
                                return cellValue >= searchTerm;
                            }
                        }
                    }
                ]
            };


        }

        angular.module("app").controller("consignmentComplianceCtrl", controllers.compliance.ConsignmentComplianceCtrl);
    }
}